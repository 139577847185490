<template>
    <section>
        <modal ref="modalProductos" :titulo="titulo" no-aceptar tamano="modal-lg">
            <template slot="header-slot">
                <div class="col-12 text-center text-general pt-0 pb-2 f-600">
                    {{ fecha }}
                </div>
                <el-tooltip placement="bottom" content="Descargar excel" effect="light">
                        <div class="btn-action border ml-lg-auto mr-5">
                            <vue-excel-xlsx
                            class="btn"
                            :data="dataPlantilla"
                            :columns="fieldsPlantilla"
                            :filename="'productos'"
                            :sheetname="'sheetname'"
                            >
                            <i class="icon-descargar f-18 text-general" />
                        </vue-excel-xlsx>
                        </div>
                    </el-tooltip>
            </template>
            <div v-for="(producto,p) in productos" :key="p" class="row mx-0 mt-2 px-0 justify-center">
                <div class="col-auto">
                    <img :src="producto.foto_firmada" class="border br-12" style="width:50px;height:50px;object-fit:cover;" alt="" />
                </div>
                <div class="col-8">
                    <p class="mb-0 f-15 f-600 text-general">
                        {{ producto.sku }} - {{ producto.nombre }} - {{ producto.presentacion }}
                    </p>
                    <div class="row">
                        <div class="col-auto f-14 text-general2">
                            {{ params.tipo == 1 ? separadorNumero(producto.valor) : `${agregarSeparadoresNumero(producto.valor)} Unidades` }}
                        </div>
                        <div class="col-auto ml-auto f-18 f-600 text-general2">
                            {{ producto.porcentaje }}%
                        </div>
                    </div>
                    <div class="row" style="height:10px;">
                        <div class="h-100 bg-light-f5 br-5" :style="`width:${producto.porcentaje_max}%`" />
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Metricas from "~/services/metricas_admin"
import VueExcelXlsx from "vue-excel-xlsx";
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)
Vue.use(VueExcelXlsx)

export default {
    data(){
        return{
            productos: [],
            params: {
                fecha_inicio: null,
                fecha_fin: null,
                tipo: null,
                id_cedis: null,
                id_moneda: null,
            },
            fecha:'',
            titulo: '',
            dataPlantilla:[],
        }
    },
    computed:{
        fieldsPlantilla(){
            return[
                {
                    label: "barcode",
                    field: "barcode",
                },
                {
                    label: "nombre",
                    field: "nombre",
                },
                {
                    label: "descripción",
                    field: "descripcion",
                },
                {
                    label: "presentación",
                    field: "presentacion",
                },
                {
                    label: this.params.tipo == 1 ? "valor" :"cantidad",
                    field: "cantidad",
                },
            ]
        },
    },
    methods: {
        toggle(params){
            this.$refs.modalProductos.toggle()
            this.params = params
            this.productos = []

            this.fecha = `Durante : ${this.formatearFecha(params.fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(params.fecha_fin,'D MMM YYYY')}`
            this.titulo = params.tipo == 1 ? 'Productos más comprados en cuanto a dinero' : 'Productos más comprados en cuanto a cantidad'

            this.ver_mas_productos()
        },
        async ver_mas_productos(){
            try {
                const { data } = await Metricas.ver_mas_productos(this.params)
                this.productos = data.data.productos
                this.dataPlantilla = this.productos.map(el => {
                    return  {
                        barcode: el.barcode,
                        nombre:el.nombre,
                        descripcion: el.descripcion,
                        presentacion: el.presentacion,
                        cantidad: this.params.tipo == 1 ? el.valor : el.cantidad
                    }
                })

            } catch (error){

            }
        },
    }
}
</script>
<style lang="scss">
.bg-gradient-general{
    background: var(--gr-r-general);
}
.text-blue-modal{
    color: #0D4DFF;
}
</style>
